import { type FC, type PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { usePageTracking } from 'utils/ga';
import PublicLayout from 'components/templates/PublicLayout';

const Company: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  return (
    <PublicLayout>
      <Helmet>
        <title>会社概要: サイト分析、レポートはGrowth Support</title>
      </Helmet>
      <section className="c-section--primary" id="company">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
          <header className="c-section__header">
            <h2 className="c-section__title">会社概要</h2>
          </header>
          <div className="c-section__body">
            <div className="p-reasons">
              <div className="p-reasons__container">
                <table className="company-table">
                  <tbody>
                    <tr className="company-content">
                      <th className="header font-normal">社名</th>
                      <td className="body">fives株式会社</td>
                    </tr>
                    <tr className="company-content">
                      <th className="header font-normal">所在地</th>
                      <td className="body">
                        東京都練馬区練馬1-20-8 日建練馬ビル2F
                      </td>
                    </tr>
                    <tr className="company-content">
                      <th className="header font-normal">設立年月</th>
                      <td className="body">2019年6月</td>
                    </tr>
                    <tr className="company-content">
                      <th className="header font-normal">代表者</th>
                      <td className="body">池田 竜哉</td>
                    </tr>
                    <tr className="company-content">
                      <th className="header font-normal">事業内容</th>
                      <td className="body">
                        Webサイト運営
                        <br />
                        Webサイト開発支援
                      </td>
                    </tr>
                    <tr className="company-content">
                      <th className="header font-normal">技術顧問</th>
                      <td className="body">佐藤 竜之介</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
};

export default Company;
